$(document).ready(function () {
    // Navbar Resize
    $(window).resize(function () {
        var width = $(window).width();
        var height = $(window).height();

        if (width <= 991) {
            $("#navbar").removeClass("loaded-top");
            $("#navbar").addClass("fixed-top");
            $("#logo-fidel").attr(
                "src",
                "../../../assets/img/fidel_logo_roxa_app.png"
            );
        }

        $(window).scroll(function () {
            var width = $(window).width();
            var height = $(window).height();

            if (width > 992) {
                if ($(this).scrollTop() > 360 && $(this).scrollTop() < 585) {
                    $("#navbar").addClass("scrolling");
                    $("#logo-fidel").attr(
                        "src",
                        "../../../assets/img/fidel_logo_roxa_app.png"
                    );
                } else if ($(this).scrollTop() > 585) {
                    $("#navbar").addClass("fixed-top");
                    $("#navbar").removeClass("loaded-top");
                    $("#navbar").removeClass("scrolling");
                    $(".wrapper-content").addClass("fix-nav");
                    $("#logo-fidel").attr(
                        "src",
                        "../../../assets/img/fidel_logo_roxa_app.png"
                    );
                    // $('.nav-item .btn-sm').removeClass("btn-primary");
                    // $('.nav-item .btn-sm').addClass("btn-secondary");
                } else {
                    $("#navbar").addClass("loaded-top");
                    $("#navbar").removeClass("scrolling");
                    $("#navbar").removeClass("fixed-top");
                    $(".wrapper-content").removeClass("fix-nav");
                    $(".nav-item .btn-sm").removeClass("btn-secondary");
                    $(".nav-item .btn-sm").addClass("btn-primary");
                    $("#logo-fidel").attr(
                        "src",
                        " ../../../../assets/img/logo_app.png"
                    );
                }
            } else if (width < 991) {
                if ($(this).scrollTop() > 585) {
                    $(".cta-fixed-bottom").addClass("rollIn");
                } else {
                    $(".cta-fixed-bottom").removeClass("rollIn");
                }
                if (
                    $(window).scrollTop() + $(window).height() ==
                    $(document).height()
                ) {
                    $(".cta-fixed-bottom").removeClass("rollIn");
                }
            }
        });
        $(window).scroll();
    });
    $(window).resize();
});

$(document).ready(function () {
    // Initialize swiper when Document Ready
    var mySwiper = new Swiper ('.swiper-container', {
      // Optional Parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 3,
      spaceBetween: 12,
      initialSlide: 1,
      centeredSlides: true,

      // Responsive breakpoints
      breakpoints: {
        // When window width is <= 992px
        992: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        // When window width is <= 1200px
        1200: {
          slidesPerView: 2,
          spaceBetween: 10
        },
      },

      // Pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation Arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  });
